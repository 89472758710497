
export default class GroupItemFactory
{
  static createGroup(title, order) {
    return {
      title: title,
      items: [],
      order: order
    };
  }

  static createItem(smallTitle, bigTitle, highlightTypeName, highlightUid, detailUri, isNationwide) {
    return {
      smallTitle: smallTitle,
      bigTitle: bigTitle,
      highlightTypeName: highlightTypeName,
      highlightUid: highlightUid,
      detailUri: detailUri,
      isNationwide: isNationwide
    };
  }
}
