/*
 * Copyright(c) 2021 codemacher UG (haftungsbeschränkt) All Rights Reserved.
 */
import MapControl from "./Ol/MapControl";
import SummaryControl from "./SummaryControl";
import ItemsControl from "./ItemsControl";

import FilterMediator from "./FilterMediator";
import OfferUtil from "./Utils/OfferUtil";

//
// Creates the application, connects all stuff.
//
export default class MapBuilder {

  run() {
    this.$targetNode = $(".locationmap");
    const isSchoFeierabend = (this.$targetNode.length === 0);
    if (isSchoFeierabend) {
      return;
    }

    this.$targetNode.show();
    this.$targetNode.siblings('.content-blocker').hide();

    var apiUrl = this.$targetNode.data("apiurl");
    var that = this;
    if (apiUrl) {
      $.getJSON(apiUrl, function (msg) {
        if (msg.status == 1) {
          that.onDataLoaded(msg);
        } else {
          that.onFailure(msg);
        }
      });
    }
  }

  prepairData(data) {
    for(var i=0;i<data.offers.length;i++) {
      data.offers[i].district =  data.offers[i].district.split(",");
      data.offers[i].institution = OfferUtil.findInstitutionForOffer(data.offers[i],data);
    }
    return data;
  }

  onDataLoaded(msg) {
    var data = this.prepairData(msg.data);
    var mapControl = new MapControl($(".map", this.$targetNode),msg.settings);
    var summaryControl = new SummaryControl($(".infopanel .wrapper .summaryControl",this.$targetNode));
    var itemsControl = new ItemsControl($(".infopanel .wrapper .itemsControl",this.$targetNode));

    var filterMediator = new FilterMediator(data);
    filterMediator.connectTo(itemsControl);
    filterMediator.connectTo(mapControl);
    filterMediator.connectTo(summaryControl);
    filterMediator.applyFilter();

  }

  onFailure(msg) {
    console.error("status = " + msg.status);
  }
}
