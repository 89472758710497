/* 
 * Copyright(c) 2021 codemacher UG (haftungsbeschränkt) All Rights Reserved.
 */


export default class ObjectUtils
{
  static isNullOrEmpty(obj) {
    return !obj || Object.keys(obj).length === 0
  }

  static isEmpty(obj) {
    return Object.keys(obj).length === 0
  }
}
