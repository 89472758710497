/* 
 * Copyright(c) 2021 codemacher UG (haftungsbeschränkt) All Rights Reserved.
 */
import { Fill, Stroke, Style, Text } from 'ol/style';

export default class LocationMapStyling {

    static get color_almostButNotCompleteTransparent() {
        return 'rgba(255,255,255,  0.01)'; // must not be transparent, otherwise we convert it to grayscale in MapControl.js
    }

    static get hoverStyle() {
        if (!LocationMapStyling._hoverStyle) {
            LocationMapStyling._hoverStyle = new Style({
                fill: new Fill({
                    color: LocationMapStyling.color_almostButNotCompleteTransparent
                }),
                stroke: new Stroke({
                    color: 'rgba(0, 0, 0, 1)',
                    width: 3,
                }),
            });
        }
        return LocationMapStyling._hoverStyle;
    }

    static get selectedStyle() {
        if (!LocationMapStyling._highlightStyle) {
            LocationMapStyling._highlightStyle = new Style({
                fill: new Fill({
                    color: LocationMapStyling.color_almostButNotCompleteTransparent
                }),
                stroke: new Stroke({
                    color: '#00AA00',
                    width: 3,
                }),
            });
        }
        return LocationMapStyling._highlightStyle;
    }

    static get defaultStyle() {
        if (!LocationMapStyling._style) {
            LocationMapStyling._style = new Style({
                fill: new Fill({
                    color: '#8aea4b55' // found by sharp looking
                }),
                stroke: new Stroke({
                    color: 'rgba(0, 0, 0, 1)',
                    width: 1,
                }),
                text: new Text(),
            });
        }
        return LocationMapStyling._style;
    }

}