/*
 * Copyright(c) 2021 codemacher UG (haftungsbeschränkt) All Rights Reserved.
 */

import Map from 'ol/Map';
import OSM from 'ol/source/OSM';
import TileLayer from 'ol/layer/Tile';
import View from 'ol/View';
import GeoJSON from 'ol/format/GeoJSON';
import { get as getProjection } from 'ol/proj';
import { fromLonLat } from 'ol/proj';
import Raster from 'ol/source/Raster';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Image as ImageLayer } from 'ol/layer';
import { DragPan, defaults } from 'ol/interaction';
import { noModifierKeys } from "ol/events/condition";
import FilterMediatorControl from "../FilterMediatorControl";
import LocationMapStyling from "./LocationMapStyling";
import LocationMapMarkers from "./LocationMapMarkers";

export default class MapControl extends FilterMediatorControl {



    constructor($target, settings) {
        super();
        this.$mapTarget = $(".olmap", $target);
        this.$infoTarget = $(".info", $target);
        this.initMap(settings);

        const that = this;
        this.criterion_Is_VectorLayer = function (layer) {
            return layer === that.vectorLayer;
        };
        this.criterion_Not_VectorLayer = function (layer) {
            return layer !== that.vectorLayer;
        };

        this._selectedOffer = null;
        this._selectedInstitution = null;
        this._selectedStory = null;

        this._selectedFeature = null;

        this._hoverMarker = null;
        this._hoverDistrict = null;

        this.enableFocusOnMove = true;
        this.suspendUserInteraction = false;
    }

    createMapLayer() {

        var osm = new TileLayer({
            source: new OSM(),
        });
        return osm;
    }

    onFilterChange(filteredData, filterSet, fullData) {
        this.fullData = fullData;
        if (this.locationMapMarker) {
            this.locationMapMarker.createMarkers(fullData);
        }

        if (filterSet && filterSet.propertyFilter && filterSet.propertyFilter.district) {
            const districtId = filterSet.propertyFilter.district;
            const districtFeature = this.findDistrictFeature(districtId);
            this.setSelectedFeature(districtFeature);
            this.fitSelectedFeature();
        }
    }

    toHighlightTypeInfo(info) {
        var typeName;
        if (info.institution) typeName = "institution";
        if (info.offer)       typeName = "offer";
        if (info.story)       typeName = "story";
        if (info.district)    typeName = "district";

        var result = {};
        result.typeName = typeName;
        result.data = info[typeName];
        return result;
    }

    onHighlightChange(info, data, filterSet, fullData) {
        if (! info) {
            this.locationMapMarker.removeHighlight();
            this.removeDistrictHighlight();
            return;
        }

        const typeInfo = this.toHighlightTypeInfo(info);
        switch (typeInfo.typeName) {
            case "institution":
            case "offer":
            case "story":
                this.locationMapMarker.highlightByUid(typeInfo.typeName, typeInfo.data);
                break;
            case "district":
                this.highlightDistrict(typeInfo.data);
                break;
        }
    }

    highlightDistrict(districtId) {
        var districtFeature = this.findDistrictFeature(districtId);
        if(districtFeature) {
            districtFeature.setStyle(LocationMapStyling.hoverStyle);
            var title = this.getTitleFromFeature(districtFeature);
            this.$infoTarget.html(title);
        }
    }

    removeDistrictHighlight() {
        const distFeat = this.source.getFeatures();
        for (let i=0; i < distFeat.length; ++i) {
            const df = distFeat[i];
            if (df !== this._selectedFeature) {
                df.setStyle(LocationMapStyling.defaultStyle);
            }
        }
    }

    findDistrictFeature(districtId) {
        const distFeat = this.source.getFeatures();
        for (let i=0; i < distFeat.length; ++i) {
            const df = distFeat[i];
            if (df.get("AGS") === districtId) {
                return df;
            }
        }
    }

    clearDetailSelection(noFilterUpdate) {
        this._selectedOffer = null;
        this._selectedInstitution = null;
        this._selectedStory = null;
        if(noFilterUpdate) return;
        this.updateFilter();
    }

    bringMarkerFeatureInFront(feature) {
        this.locationMapMarker.markerSource.removeFeature(feature);
        this.locationMapMarker.markerSource.addFeature(feature);
    }

    setSelectedStory(feature) {
        if (this._selectedStory == feature) return;
        this.clearDetailSelection(true);
        this._selectedStory = feature;
        this.bringMarkerFeatureInFront(this._selectedStory);
        this.updateFilter();
    }

    getSelectedStory() {
        return this._selectedStory;
    }

    setSelectedOffer(feature) {
        if (this._selectedOffer == feature) return;
        this.clearDetailSelection(true);
        this._selectedOffer = feature;
        this.bringMarkerFeatureInFront(this._selectedOffer);
        this.updateFilter();
    }

    getSelectedOffer() {
        return this._selectedOffer;
    }

    setSelectedInstitution(feature) {
        if (this._selectedInstitution == feature) return;
        this.clearDetailSelection(true);
        this._selectedInstitution = feature;
        this.bringMarkerFeatureInFront(this._selectedInstitution);
        this.updateFilter();
    }

    getSelectedInstitution() {
        return this._selectedInstitution;
    }

    setSelectedFeature(feature) {
        if (this._selectedFeature == feature) return;

        if (this._selectedFeature) {
            this._selectedFeature.setStyle(undefined);
        }
        this._selectedFeature = feature;
        if (this._selectedFeature) {
            this._selectedFeature.setStyle(LocationMapStyling.selectedStyle);
        }

        this.updateFilter();
    }

    getSelectedFeature() {
        return this._selectedFeature;
    }

    updateFilter() {
        this.suspendUserInteraction = true;
        var filterSet;
        if (this._selectedInstitution) {
            filterSet = this.makeFilterSet("institution.uid", this._selectedInstitution.get('data').uid, this._selectedInstitution);
        } else if (this._selectedOffer) {
            filterSet = this.makeFilterSet("uid", this._selectedOffer.get('data').uid, this._selectedOffer, "offer");
        } else if (this._selectedStory) {
            filterSet = this.makeFilterSet("uid", this._selectedStory.get('data').uid, this._selectedStory, "story");
        } else if (this._selectedFeature) {
            filterSet = this.makeFilterSet("district", this._selectedFeature.get('AGS'), this._selectedFeature);
        } else {
            filterSet = undefined;
        }

        this.doApplyFilter(filterSet);
        // einmal events abarbeiten und dann erst weiter
        setTimeout(() => {
            this.suspendUserInteraction = false;
        }, 0);
    }

    makeFilterSet(propName, propValue, feature, typeName) {
        var filterSet = {
            propertyFilter: {
                [propName]: [propValue]
            },
            feature: feature
        };
        if (typeName) {
            filterSet.type = typeName;
        }
        return filterSet;
    }

    findFeatureAtPixel(pixel, layerCriterion) {
        return this.map.forEachFeatureAtPixel(
            pixel,
            function (feature) { return feature; },
            {
                layerFilter: layerCriterion,
                hitTolerance: 3
            }
        );
    }

    initMap(settings) {
        const swissProjection = getProjection('EPSG:25832');
        const that = this;

        this.source = new VectorSource({
            url: settings.sageojson,
            attributions: '<br>Geodaten: <a href="https://gdz.bkg.bund.de/" target="_blank">&copy; Geodatenzentrum © GeoBasis-DE / BKG 2018</a> ',
            format: new GeoJSON({ defaultDataProjection: 'EPSG:25832' })
        });


        this.vectorLayer = new VectorLayer({
            renderMode: 'image',
            style: function (feature) {
                var _style = LocationMapStyling.defaultStyle;
                _style.getText().setText(feature.get('name'));
                return _style;
            },
            source: this.source
        });

        var mapLayer = this.createMapLayer();

        var raster = new Raster({
            sources: [
                mapLayer,
                this.vectorLayer

            ],
            operation: function (pixels, data) {

                const pixel = pixels[0];
                const pixelL2 = pixels[1];
                const isTransparent = (pixelL2[3] == 0);
                const converToGrayscale = isTransparent; // so for overlay colors, don't use full transparent colors (give it at least a very small opacity)
                if (converToGrayscale) {
                    var r = pixel[0];
                    var g = pixel[1];
                    var b = pixel[2];
                    var v = 0.2126 * r + 0.7152 * g + 0.0722 * b;

                    pixel[0] = v; // Red
                    pixel[1] = v; // Green
                    pixel[2] = v; // Blue
                    return pixel;
                } else {
                    return pixel;
                }
            }
        });

        var rasterLayer = new ImageLayer({
            source: raster
        });

        that.vectorLayer.once('change', function (e) {
            var padding = [40, 15, 15, 15];
            if (window.screen.width >= 992) {
                padding = [15, 15, 15, 15];
            }
            that.map.getView().fit(that.source.getExtent(), { "padding": padding });
        });

        var center = fromLonLat([parseFloat(settings.lng), parseFloat(settings.lat)]);
        this.map = new Map({
            layers: [that.vectorLayer],
            target: this.$mapTarget[0],
            interactions: defaults({ dragPan: false, mouseWheelZoom: true }),
            view: new View({
                projection: swissProjection,
                center: center,
                zoom: 7.5,
            }),
        });

        // Workaround: JS Fehler den ich nicht verstehen, scheint ein zeitliches Problem zu sein
        this.source.on('featuresloadend', function () {
            that.map.removeLayer(that.vectorLayer);
            that.map.addLayer(rasterLayer);
            that.map.addLayer(that.vectorLayer);
            that.locationMapMarker = new LocationMapMarkers(that, settings);
            if (that.fullData) {
                that.locationMapMarker.createMarkers(that.fullData);
            }
        });

        this.map.addInteraction(new DragPan({
            condition: function (e) {
                return noModifierKeys(e) && (!/Mobi|Android/i.test(navigator.userAgent) || this.targetPointers.length === 2)
            }
        }));

        this.map.on('pointermove', function (e) {
            that.on_pointermove(e);
        });

        this.$mapTarget.on( "mouseleave", function() {
            that.newHoverDistrict(null);
            that.newHoverMarker(null);
            that.$infoTarget.empty();
        });
        this.map.on("moveend", function () {
            that.on_moveend();
        });
        this.map.on('singleclick', function(e) {
            that.on_singleclick(e)
        });
    }

    newHoverDistrict(pointedDistrict) {

        if (this._hoverDistrict && this._hoverDistrict !== this._selectedFeature) {
            this._hoverDistrict.setStyle(LocationMapStyling.defaultStyle);
        }
        if (pointedDistrict && pointedDistrict !== this._selectedFeature) {
            pointedDistrict.setStyle(LocationMapStyling.hoverStyle);
        }

        this._hoverDistrict = pointedDistrict;

        if (! this._hoverDistrict) {
            return;
        }

        const title = this.getTitleFromFeature(this._hoverDistrict);
        this.$infoTarget.html(title);
        const districtId = this._hoverDistrict.get("AGS");
        this.doApplyHighlight({district: districtId});
    }

    newHoverMarker(pointedMarker) {
        this.locationMapMarker.removeHighlight();

        this._hoverMarker = pointedMarker;

        if (! this._hoverMarker) {
            this.doApplyHighlight(null);
            return;
        }

        const data = this._hoverMarker.get("data");
        const type = this._hoverMarker.get("type");
        this.locationMapMarker.highlightByUid(type, data.uid);
        this.bringMarkerFeatureInFront(pointedMarker);
        const info = {
            [type]: data.uid
        };
        this.doApplyHighlight(info);

    }

    on_pointermove(e) {
        var pointedMarker = null;
        const clusterFeature = this.findFeatureAtPixel(e.pixel, this.criterion_Not_VectorLayer);
        if (clusterFeature) {
            var markerFeatures = clusterFeature.get("features");
            if (markerFeatures.length == 1) {
                pointedMarker = markerFeatures[0];
            }
        }
        if (pointedMarker !== this._hoverMarker) {
            this.newHoverMarker(pointedMarker);
        }

        const pointedDistrict = this.findFeatureAtPixel(e.pixel, this.criterion_Is_VectorLayer);
        if (pointedDistrict !== this._hoverDistrict) {
            this.newHoverDistrict(pointedDistrict);
        }
    }

    on_moveend() {
        if(this.suspendUserInteraction) return;
        var zoom = this.map.getView().getZoom();

        if (!this.enableFocusOnMove) {
            return;
        }

        if (zoom < 8) {
            this.setSelectedFeature(null);
            return;
        }

        var centerMap = [
            this.$mapTarget.width() / 2.0,
            this.$mapTarget.height() / 2.0
        ];

        this.setSelectedFeature(this.findFeatureAtPixel(centerMap, this.criterion_Is_VectorLayer));
    }

    on_singleclick(e) {
        var markerHit = this.findFeatureAtPixel(e.pixel, this.criterion_Not_VectorLayer);

        if (markerHit) {
            this.enableFocusOnMove = false;
        }

        var newSelectedFeature = this.findFeatureAtPixel(e.pixel, this.criterion_Is_VectorLayer);

        if (newSelectedFeature == this.getSelectedFeature()) {
            setTimeout(() => {
                this.enableFocusOnMove = true;
            }, 50);
            return;
        }
        this.setSelectedFeature(newSelectedFeature);

        if (!markerHit && this.getSelectedFeature()) {
            this.enableFocusOnMove = false;
            this.fitSelectedFeature();
        }

        setTimeout(() => {
            this.enableFocusOnMove = true;
        }, 50);
    }

    fitSelectedFeature() {
        if (this.getSelectedFeature()) {
            const extent = this.getSelectedFeature().getGeometry().getExtent();
            this.map.getView().fit(extent);
        }
    }

    getTitleFromFeature(feature) {
        var title = feature.get('GEN');
        if (!title) {
            title = feature.get('data').listTitle;
            if (!title) {
                title = feature.get('data').name;
            }
        }
        return title;
    }
};
