/*
 * Copyright(c) 2021 codemacher UG (haftungsbeschränkt) All Rights Reserved.
 */
import FilterMediatorControl from "./FilterMediatorControl";
import GroupItemFactory from "./GroupItemFactory";

export default class ItemsControl extends FilterMediatorControl {

    static get TITLE_GROUP_NEWS() { return "News"; }
    static get TITLE_GROUP_STORIES() { return "Gründerstorys"; }

    constructor($target) {
        super();
        this.$target = $target;
    }

    buildNewsGroup(data_news) {
        const newsGroup = GroupItemFactory.createGroup(ItemsControl.TITLE_GROUP_NEWS, 1);
        for (let i=0; i<data_news.length; ++i) {
            const newsi = data_news[i];

            const title = newsi.title;
            const uri = newsi.internalurl;
            newsGroup.items.push(
                GroupItemFactory.createItem(null, title, null, null, uri)
            );
        }
        return newsGroup;
    }

    buildOfferGroupMap(data_offers) {
        const offerGroupMap = {};
        for (let i=0; i<data_offers.length; ++i) {
            const offeri = data_offers[i];

            const bigTitle = offeri.listTitle;
            const hasOwnPos = (offeri.lng && offeri.lat);
            const highlightTypeName = hasOwnPos ? "offer" : "institution";
            const highlightUid = hasOwnPos ? offeri.uid : offeri.institution?.uid;
            const smallTitle = offeri.institution?.name;
            const uri = offeri.detailpage;
            const isNationwide = offeri.isNationwide;

            const item = GroupItemFactory.createItem(smallTitle, bigTitle, highlightTypeName, highlightUid, uri, isNationwide);

            for (let key in offeri.demands) { // demand = "Beratung und Qualifizierung" (= category)
                const demand = offeri.demands[key];
                const groupName = demand.title;
                if (! offerGroupMap.hasOwnProperty(groupName)) {
                    offerGroupMap[groupName] = GroupItemFactory.createGroup(groupName, 2);
                }
                offerGroupMap[groupName].items.push(item);
            }
        }
        return offerGroupMap;
    }

    buildStoryGroup(data_stories) {
        const storyGroup = GroupItemFactory.createGroup(ItemsControl.TITLE_GROUP_STORIES, 3);
        for (let i=0; i<data_stories.length; ++i) {
            const story = data_stories[i];

            const title = story.title;
            const uri = story.detailpage;
            const highlightTypename = "story";
            const highlightUid = story.uid;
            storyGroup.items.push(
                GroupItemFactory.createItem(null, title, highlightTypename, highlightUid, uri)
            );
        }
        return storyGroup;
    }

    sortGroups(groupList) {
        groupList.sort(function(groupA,groupB){
            const descA = (groupA.order + " " + groupA.title).toLocaleLowerCase();
            const descB = (groupB.order + " " + groupB.title).toLocaleLowerCase();
            if (descA < descB) return -1;
            if (descA > descB) return  1;
            return 0;
        });
        return groupList;
    }

    sortGroupItems(groupList) {
        for (let i=0; i<groupList.length; ++i) {
            const group = groupList[i];
            function lowcase(s) {
                if (!s) { return ""; }
                return s.toLowerCase();
            }
            group.items.sort(function(itemA, itemB) {
                const descriptorA = ("" + itemA.isNationwide + "_" + itemA.smallTitle + "_" + itemA.bigTitle).toLocaleLowerCase();
                const descriptorB = ("" + itemB.isNationwide + "_" + itemB.smallTitle + "_" + itemB.bigTitle).toLocaleLowerCase();
                if (descriptorA < descriptorB) return -1;
                if (descriptorA > descriptorB) return  1;
                return 0;
            });
        }
    }

    buildRenderGroups(data) {
        const groupList = [];

        const newsGroup = this.buildNewsGroup(data.news);
        if (newsGroup.items.length > 0) {
            groupList.push(newsGroup);
        }

        const offerGroupMap = this.buildOfferGroupMap(data.offers);
        for (let groupName in offerGroupMap) {
            const offerGroup = offerGroupMap[groupName];
            groupList.push(offerGroup);
        }

        const storyGroup = this.buildStoryGroup(data.stories);
        if (storyGroup.items.length > 0) {
            groupList.push(storyGroup);
        }

        this.sortGroups(groupList);
        this.sortGroupItems(groupList);

        return groupList;
    }

    onFilterChange(data) {
        const that = this;
        this.$target.empty();
        const $simpleBarContentElement = this.$target.closest('.simplebar-content-wrapper');
        if ($simpleBarContentElement.length > 0) {
            $simpleBarContentElement[0].scrollTop = 0;
        }

        const groupList = this.buildRenderGroups(data);
        for (let i=0; i<groupList.length; ++i) {
            const group = groupList[i];
            const $groupNode = $(`<section><h3>${group.title}</h3></section>`);

            const $listNode = $("<ol></ol>");
            for (let j=0; j<group.items.length; ++j) {
                const item = group.items[j];
                // dass ich dafür Verwendung habe... Hammer!
                // ?? = nullish-coalescing-operator
                const listItemMarkup = `
                <li data-highlight-typename="${item.highlightTypeName ?? ''}" data-highlight-uid="${item.highlightUid ?? ''}">
                  <a href="${item.detailUri}">
                    <span class="smallTitle${item.smallTitle ? '' : ' empty'}">${item.smallTitle}</span>
                    <span class="bigTitle${item.bigTitle   ? '' : ' empty'}">${item.bigTitle}</span>
                  </a>
                </li>
                `;
                $listNode.append($(listItemMarkup));
            }
            $groupNode.append($listNode);
            this.$target.append($groupNode);
        }

        $("li", this.$target).on("mouseenter", function (e) {
            const $this = $(this);
            const typename = $this.data("highlight-typename");
            const uid = $this.data("highlight-uid");
            if (typename && uid) {
                that.doApplyHighlight({[typename] : uid});
            }
        });
        $("li", this.$target).on("mouseleave", function (e) {
            that.doApplyHighlight(null);
        });
    }


    onHighlightChange(info) {
        $('li', this.$target).removeClass("highlight");
        if (!info) {
            return;
        }
        ///
        /// info = {'institution':37}
        ///
        const typename = Object.keys(info)[0];
        const uid = info[typename];
        const selector = `li[data-highlight-typename='${typename}'][data-highlight-uid='${uid}']`;
        $(selector).addClass("highlight");
    }
}
