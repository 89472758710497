/* 
 * Copyright(c) 2021 codemacher UG (haftungsbeschränkt) All Rights Reserved.
 */

import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Style, Icon, Circle, Stroke, Fill, Text } from 'ol/style';
import { fromLonLat } from 'ol/proj';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point'
import { Cluster } from 'ol/source';
import LocationMapPopUp from './LocationMapPopUp';

export default class LocationMapMarkers {

  constructor(locationMap, settings) {
    this.locationMap = locationMap;
    this.settings = settings;
  }

  createMarkers(data) {

    var that = this;
    if (this.data) return;
    this.data = data;

    this.markerSource = new VectorSource();

    this.clusterSource = new Cluster({
      distance: 30,
      source: this.markerSource,
    });

    this.markerLayer = new VectorLayer({
      source: this.clusterSource,
    });

    var iconStyleInstitution = new Style({
      image: new Icon({
        anchor: [0.5, 1],
        scale: 1,
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        src: this.settings.markerurl_institution
      }),
    });

    var iconStyleOffer = new Style({
      image: new Icon({
        anchor: [0.5, 1],
        scale: 1,
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        src: this.settings.markerurl_offer
      }),
    });

    var iconStyleStory = new Style({
      image: new Icon({
        anchor: [0.5, 1],
        scale: 1,
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        src: this.settings.markerurl_story
      }),
    });

    var iconStyleHighlight = new Style({
      image: new Icon({
        anchor: [0.5, 1],
        scale: 1,
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        src: this.settings.markerurl_highlight
      }),
    });

    var styleCache = {};
    this.markerLayer.setStyle(function (feature, resolution) {
      var size = feature.get('features').length;
      if (size == 1 /*&& resolution < that.locationMap.map.getView.getResolutionForZoom(6)*/) {
        // if a cluster of one show the normal icon


        var allFeatures = feature.get("features");
        var marker = allFeatures[0];
        if (that.currentHighlightMarker == marker) {
          return iconStyleHighlight;
        }

        var type = marker.get('type');
        if (type == "institution") {
          return iconStyleInstitution;
        } else if (type == "offer") {
          return iconStyleOffer;
        } else {
          return iconStyleStory;
        }

      } else {
        // otherwise show the number of features
        var style = styleCache[size];
        if (!style) {
          style = new Style({
            image: new Circle({
              radius: 10,
              stroke: new Stroke({
                color: '#000'
              }),
              fill: new Fill({
                color: '#000'
              })
            }),
            text: new Text({
              text: size.toString(),
              font: 'bold 10px sans-serif',
              fill: new Fill({
                color: '#fff'
              })
            })
          });
          styleCache[size] = style;
        }
        return style;
      }


    });

    this.locationMap.map.addLayer(this.markerLayer);
    var map = this.locationMap.map;

    map.getView().on('change:resolution', function (evt) {
      var view = evt.target;
      var source = that.clusterSource;
      if (view.getZoom() >= 10) {
        source.setDistance(0);
      }
      else if (view.getZoom() < 9) {
        source.setDistance(40);
      }

    });

    this.addAllMarkers("institution", data.institutions);
    this.addAllMarkers("offer", data.offers);
    this.addAllMarkers("story", data.stories);
    new LocationMapPopUp(this.locationMap, this.markerLayer);

  }

  addAllMarkers(type, list) {
    for (var i = 0; i < list.length; ++i) {
      var item = list[i];
      if (item.lat && item.lng) {
        this.addMarker(type, item);
      }
    }
  }

  addMarker(type, obj) {
    if (obj.lng && obj.lat) {
      var pos = fromLonLat([parseFloat(obj.lng), parseFloat(obj.lat)]);
      var iconFeature = new Feature({
        geometry: new Point(pos),
        name: obj.name,
        data: obj,
        type: type
      });
      this.markerSource.addFeature(iconFeature);
    }
  }

  set currentHighlightMarker(marker) {
    if (this._currentHighlightMarker == marker) return;
    this._currentHighlightMarker = marker;
    if(marker) {
      this.locationMap.bringMarkerFeatureInFront(marker);
    }
    this.markerLayer.changed();
  }

  get currentHighlightMarker() {
    return this._currentHighlightMarker;
  }

  removeHighlight() {
    this.currentHighlightMarker = null;
  }

  highlightByUid(targetType, uid) {
    var markers = this.markerSource.getFeatures();
    var hasFound = false;
    for (var i = 0; i < markers.length; i++) {
      var marker = markers[i];
      if (marker.get('type') == targetType
       && marker.get('data').uid == uid)
      {
        this.currentHighlightMarker = marker;
        hasFound = true;
      }
    }
    if (!hasFound) {
      this.currentHighlightMarker = null;
    }
  }
}
