/* 
 * Copyright(c) 2021 codemacher UG (haftungsbeschränkt) All Rights Reserved.
 */

import Overlay from 'ol/Overlay';

export default class LocationMapPopUp {
  constructor(locationMap, markerLayer) {
    this.locationMap = locationMap;
    this.markerLayer = markerLayer;

    $("body").append(this.builderPopUpNode());
    var container = document.getElementById('popup');
    var content = document.getElementById('popup-content');
    var closer = document.getElementById('popup-closer');

    var overlay = new Overlay({
      element: container,
      autoPan: true,
      autoPanAnimation: {
        duration: 250
      }
    });
    this.locationMap.map.addOverlay(overlay);

    closer.onclick = function () {
      overlay.setPosition(undefined);
      that.locationMap.clearDetailSelection();
      closer.blur();
      return false;
    };
    var that = this;
    var mapTarget = locationMap.map.get("target");

    locationMap.map.on('pointermove', (event) => {
      const pixel = locationMap.map.getEventPixel(event.originalEvent);
      var feature = that.locationMap.map.forEachFeatureAtPixel(pixel, function (feature) {
        return feature;
      }, {
        /*layerFilter: function (layer) {
          return layer === that.markerLayer;
        },*/
        hitTolerance: 3
      });

      mapTarget.style.cursor = feature ? 'pointer' : '';
    });

    this.locationMap.map.on('singleclick', function (event) {
      var clusterFeature = that.locationMap.map.forEachFeatureAtPixel(event.pixel, function (feature) {
        return feature;
      }, {
        layerFilter: function (layer) {
          return layer === that.markerLayer;
        },
        hitTolerance: 3
      });

      if (clusterFeature) {
        var coordinate = event.coordinate;
        var features = clusterFeature.get("features");
        if(features.length != 1) {
          that.locationMap.map.getView().animate({
            zoom: that.locationMap.map.getView().getZoom() + 1,
            center: event.coordinate,
            duration: 250
          })
          return;
        }
        var feature = features[0];
       
        var type = feature.get("type");
        if(type == "institution") {
          content.innerHTML = that.buildInstitutionHtmlforMarker(feature.get("data"));
          that.locationMap.setSelectedInstitution(feature);
        } else if(type == "offer") {
          content.innerHTML = that.buildOfferHtmlforMarker(feature.get("data"));
          that.locationMap.setSelectedOffer(feature);
        } else {
          content.innerHTML = that.buildStoryHtmlforMarker(feature.get("data"));
          that.locationMap.setSelectedStory(feature);
        }

        overlay.setPosition(coordinate);
      } else {
        overlay.setPosition(undefined);
        that.locationMap.clearDetailSelection();
        closer.blur();
      }
    });
  }

  builderPopUpNode() {
    return  $('<div id="popup" class="ol-popup"><a href="#" id="popup-closer" class="ol-popup-closer"></a><div id="popup-content"></div></div>');
  }

  addParameterToUrl(url, data)
  {
    if (!$.isEmptyObject(data))
    {
      url += (url.indexOf('?') >= 0 ? '&' : '?') + $.param(data);
    }

    return url;
  }

  buildStoryHtmlforMarker(story) {
    return `
            <h2>${story.title}</h2>
            <a class="more-link" href="${story.detailpage}">...mehr</a>
        `;
  }

  buildOfferHtmlforMarker(offer) {
    return `
            Angebot
            <h2>${offer.title}</h2>
            <a class="more-link" href="${offer.detailpage}">...mehr</a>
        `;
  }

  buildInstitutionHtmlforMarker(institution) {
    return `
            Einrichtung
            <h2>${institution.name}</h2>
            <p class="address">${institution.address}</p>
            <p class="city">${institution.zipcode} ${institution.city}</p>
            <a class="more-link" href="${institution.detailpage}">...mehr</a>
        `;
  }

}
