/* 
 * Copyright(c) 2021 codemacher UG (haftungsbeschränkt) All Rights Reserved.
 */


export default class StringUtils
{
  static tokenReplace(template, data) {
    const pattern = /{\s*(\w+?)\s*}/g; 
    return template.replace(pattern, (_, token) => data[token] || '');
  }
  
  static toString(anyObject) {
    return String(anyObject); // NOT: "new String()"
  }
  
  static simpleHtmlEntities(str) {
    /* from css-tricks.com */
    return String(str)
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
    ;
  }
}