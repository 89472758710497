/* 
 * Copyright(c) 2021 codemacher UG (haftungsbeschränkt) All Rights Reserved.
 */
export default class FilterMediatorControl {
    
    setMediator(mediator) {
        this.filterMediator = mediator;
    }

    onFilterChange(filteredData,filterSet,fullData) {

    }

    onHighlightChange(info,data,filterSet,fullData) {

    }

    doApplyFilter(filterSet) {
        this.filterMediator.applyFilter(filterSet, this);
    }
    doApplyHighlight(info) {
        this.filterMediator.applyHighlight(info, this);
    }
}
