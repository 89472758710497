/* 
 * Copyright(c) 2021 codemacher UG (haftungsbeschränkt) All Rights Reserved.
 */
import FilterEngine from "./FilterEngine";

//
// Applies the filter engine and forward
// the results to the depending controls. 
//
export default class FilterMediator {

  constructor(data) {
    this.data = data;
    this.connectedControls = [];
  }

  connectTo(control) {
    control.setMediator(this);
    this.connectedControls.push(control);
  }

  applyFilter(filterSet, callingControl) {

    this.currentFilterSet = filterSet;
    this.filteredData = FilterEngine.calc(this.currentFilterSet, this.data);

    for (var i = 0; i < this.connectedControls.length; ++i) {
      var control = this.connectedControls[i];
      if (control !== callingControl) {
        control.onFilterChange(this.filteredData, filterSet, this.data);
      }
    }
  }


  applyHighlight(info, callingControl) {
    for (var i = 0; i < this.connectedControls.length; ++i) {
      var control = this.connectedControls[i];
      if (control !== callingControl) {
        control.onHighlightChange(info, this.filteredData, this.currentFilterSet, this.data);
      }
    }
  }
}
