/* 
 * Copyright(c) 2021 codemacher UG (haftungsbeschränkt) All Rights Reserved.
 */
import FilterMediatorControl from "./FilterMediatorControl";

export default class SummaryControl extends FilterMediatorControl {
    constructor($target) {
        super();
        this.$target = $target;
        this.initialMarkup = this.$target.html();
        this.connectClickHandlerOnDistricts();
    }

    onFilterChange(data, filterSet) {
        if (filterSet) {
            this.renderHeadline(filterSet);
        } else {
            this.renderOverview();
            this.connectClickHandlerOnDistricts();
        }
    }

    onHighlightChange(info) {
        this.$target.find("li").removeClass("highlight");
        if (!info) {
            return;
        }
        if (info.district) {
            const selector = `li[data-districtid='${info.district}']`;
            this.$target.find(selector).addClass("highlight");
        }
    }

    filterByDistrict(domLi) {
        const filterSet = {
            propertyFilter: {
                district: domLi.getAttribute("data-districtid")
            }
        };
        this.doApplyFilter(filterSet);
    }

    highlightDistrict(domLi) {
        const info = domLi
            ? {
                district: domLi.getAttribute("data-districtid") // want string, jquery.data() would try to be intelligent and return a number
            }
            : null
        ;
        this.doApplyHighlight(info);
    }

    connectClickHandlerOnDistricts() {
        const that = this;
        this.$target.find("ol.initialDistrictList li")
            .mouseenter(function(evt){
                that.highlightDistrict(this);
            })
            .mouseleave(function(evt){
                that.highlightDistrict(null);
            })
            .click(function(evt){
                that.filterByDistrict(this);
            })
        ;
    }

    renderOverview() {
        this.$target.empty().html(this.initialMarkup);
    }

    renderHeadline(filterSet) {
        var contentDescription;
        var entityName;
        switch (filterSet.feature.get("type")) {
            case "institution":
                var institution = filterSet.feature.get("data");
                entityName = institution.name;
                contentDescription = "Angebote";
                break;
            case "offer":
                break;
            case "story":
                break;
            default:
                entityName = filterSet.feature.get("GEN");
                contentDescription = "Angebote & Storys";
                break;
        }

        this.$target.empty();

        if (entityName && contentDescription) {
            const markup = `<h2 class='resultHeadline'>${contentDescription} für: <strong>${entityName}</strong></h2>`;
            this.$target.append(markup).removeClass("hidden");
        } else {
            this.$target.addClass("hidden");
        }

    }
}
