/*
 * Copyright(c) 2021 codemacher UG (haftungsbeschränkt) All Rights Reserved.
 */
import Map from 'ol/Map';
import OSM from 'ol/source/OSM';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point'
import { Style, Icon } from 'ol/style';

import GeoJSON from 'ol/format/GeoJSON';
import { get as getProjection } from 'ol/proj';
import { fromLonLat } from 'ol/proj';
import Raster from 'ol/source/Raster';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Image as ImageLayer } from 'ol/layer';
import { DragPan, defaults } from 'ol/interaction';
import { noModifierKeys } from "ol/events/condition";

//
// Creates the application, connects all stuff.
//
export default class SimpleMarkerMapBuilder {
  run() {
    const mapContainerId = "simpleOlMap";
    const $mapContainer = $("#" + mapContainerId);

    const isSchoFeierabend = ($mapContainer.length === 0);
    if (isSchoFeierabend) {
      return;
    }
    // else:
    $mapContainer.html('');

    const coords = [
      $mapContainer.data("lng"), // 11.93498
      $mapContainer.data("lat")  // 51.50108
    ];
    const zoomLevel = 16;
    const mapsMarkerUrl = $mapContainer.data("markerurl"); //"/typo3conf/ext/gsa_template/Resources/Public/Icons/Content/mapsmarker_story.svg";
    const mapsMarkerScale = 1.5;


    var raster = new Raster({
        sources:[
            new TileLayer({
                source: new OSM()
            })
        ],
        operation: function (pixels, data) {
            const pixel = pixels[0];
            var r = pixel[0];
            var g = pixel[1];
            var b = pixel[2];
            var v = 0.2126 * r + 0.7152 * g + 0.0722 * b;
            pixel[0] = v; // Red
            pixel[1] = v; // Green
            pixel[2] = v; // Blue
            return pixel;
        }
    });

    var rasterLayer = new ImageLayer({
        source: raster
    });

    const map = new Map({
      target: mapContainerId,
      layers: [
        rasterLayer
      ],
      view: new View({
        center: fromLonLat(coords),
        zoom: zoomLevel
      })
    });

    const markerLayer = new VectorLayer({
      source: new VectorSource(),
      style: new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: mapsMarkerUrl,
          scale: mapsMarkerScale
        })
      })
    });
    map.addLayer(markerLayer);

    const marker = new Feature(
      new Point(
        fromLonLat(coords)
      )
    );
    markerLayer.getSource().addFeature(marker);
  }
}
