/* 
 * Copyright(c) 2021 codemacher UG (haftungsbeschränkt) All Rights Reserved.
 */
import ObjectUtils from "./Utils/ObjectUtils";
import StringUtils from "./Utils/StringUtils";
import jp from "jsonpath/jsonpath";
//
// Calculates the filtered sets of both offers and institutions
// on the given set of data and with respect to the given filter settings.
//
export default class FilterEngine {
  static calc(filterSet, fullData) {

    var result = {
      "institutions": fullData.institutions,
      "offers": [],
      "stories": [],
      "news": []
    }

    if (ObjectUtils.isNullOrEmpty(filterSet)) {
      return result;
    }

    /*for (let i=0; i<serverData.institutions.length; ++i) {
      let institution = serverData.institutions[i];
      if (FilterEngine._matches(institution, filterSet)) {
        result.institutions.push(institution);
      }
    }*/
    var type = filterSet.type;
    if (!type || type == "offer") {
      for (let i = 0; i < fullData.offers.length; ++i) {
        let offer = fullData.offers[i];
        if (FilterEngine._matches(offer, filterSet)) {
          result.offers.push(offer);
        }
      }
    }
    if (!type || type == "story") {
      for (let i = 0; i < fullData.stories.length; ++i) {
        let story = fullData.stories[i];
        if (FilterEngine._matches(story, filterSet)) {
          result.stories.push(story);
        }
      }
    }
    if (!type || type == "news") {
      for (let i = 0; i < fullData.news.length; ++i) {
        let news = fullData.news[i];
        if (FilterEngine._matches(news, filterSet)) {
          result.news.push(news);
        }
      }
    }

    return result;
  }


  static _matches(item, filterSet) {
    var hasMatch = true;
    //  console.log(filterSet);
    if (filterSet.hasOwnProperty("propertyFilter")) {
      const filterNameList = Object.keys(filterSet.propertyFilter);
      for (let i = 0; i < filterNameList.length; ++i) {
        const filterName = filterNameList[i];
        const itemProp = jp.query(item, '$.' + filterName);
        const filterValueList = filterSet.propertyFilter[filterName];
        if (itemProp.length > 0) {
          hasMatch = FilterEngine._isMatchingThatVeryFilter(itemProp[0], filterValueList);
        } else {
          return false;
        }
        /* console.log(hasMatch + "=>" + itemProp + " VS " + filterValueList);
         console.log(itemProp);*/
        if (!hasMatch) {
          return false;
        }
      }
    }

    return hasMatch;
  }

  static _isMatchingThatVeryFilter(itemProps, filterValueList) {
    var result = false;


    for (let i = 0; i < filterValueList.length; ++i) {
      const filterValue = filterValueList[i];
      result = result
        || FilterEngine._itemHasValue(itemProps, filterValue)
        || FilterEngine._itemContainsValue(itemProps, filterValue)
        ;
      if (result) {
        return true;
      }
    }

    return result;
  }

  static _itemHasValue(itemProps, value) {
    return StringUtils.toString(itemProps) === StringUtils.toString(value);
  }

  static _itemContainsValue(itemProps, value) {
    for (let key in itemProps) {
      const valueObject = itemProps[key];
      //  console.log("   " + StringUtils.toString(valueObject) + "===" + StringUtils.toString(value) + " => " + (StringUtils.toString(valueObject) === StringUtils.toString(value)));
      if (StringUtils.toString(valueObject) === StringUtils.toString(value)) {
        return true;
      }
    }
    return false;
  }


}

