import MapBuilder from "./Map/MapBuilder";
import 'simplebar/dist/simplebar.js'

import SimpleMarkerMapBuilder from "./SimpleMarkerMap/SimpleMarkerMapBuilder";

$(function() {
  new MapBuilder().run();
  new SimpleMarkerMapBuilder().run();
});

