/* 
 * Copyright(c) 2021 codemacher UG (haftungsbeschränkt) All Rights Reserved.
 */


export default class OfferUtil
{
  static findInstitutionForOffer(offer,data) {
    if(!offer.institution) return null;
    return data.institutions.find(x => x.uid === offer.institution.uid);
  }
}
